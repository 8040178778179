import classNames from 'classnames'
import { Form, Formik } from 'formik'
import Handlebars from 'handlebars'
import HtmlParser from 'html-react-parser'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Button, { type ButtonProps } from 'components/Button'
//import Buttons, { type ButtonsProps } from 'components/Buttons'
import Field2 from 'components/Field2'
import FieldCheckboxButtons from 'components/FieldCheckboxButtons'
import FieldRadioButtons from 'components/FieldRadioButtons'
import FieldSlider from 'components/FieldSlider'
import Loader from 'components/Loader'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import * as notifications from 'libs/notifications'
import * as validations from 'libs/validations'
import * as checkins from 'libs/checkins/checkin-constants'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import './CheckinForm.scss'

type Props = {
  buttonProps?: ButtonProps | {}
  //buttonsProps?: ButtonsProps | {}
  checkinTracker: CheckinTracker
  onSuccess: () => void
  size?: 'medium' | 'small'
} & Component

export default function CheckinForm({
  buttonProps = {},
  //buttonsProps = {},
  checkinTracker,
  className,
  onSuccess,
  size = 'medium',
}: Props) {
  async function handleResponse(value, key) {
    if (_.isNil(value) || _.isEmpty(value)) {
      //onChange was being triggered on mount with no value response
      console.warn('Null or empty response for key', key, 'so exiting')
      return
    }
    const prompt = checkinTracker.rootNodes.find((item) => item.code === key)
    let response

    if (prompt.type === checkins.PROMPT_TYPE_TEXT) {
      prompt.responses[0].text = value
      prompt.responses[0].value = checkins.PROMPT_TYPE_TEXT_RESPONSE_VALUE
      response = prompt.responses[0]
    } else if (prompt.type === checkins.PROMPT_TYPE_NUMBER) {
      prompt.responses[0].text = value
      prompt.responses[0].value = value
      response = prompt.responses[0]
    } else {
      response = prompt.responses.find((item) => item.value === value)
    }

    checkinTracker.handleRespond(response, prompt, false)
  }

  async function submit(values) {
    // if (Array.isArray(values)) {
    //   response = prompt.responses.find((item) => value?.includes(item.value))
    //   if (response) {
    //     response.text = value.join(', ')
    //     response.value = value.join(', ')
    //   } else {
    //     // hack to allow multi-select to be optional but still show up as last OB question
    //     // workaround for bug where questions not marked as required in the checkin-template are never displayed
    //     prompt.responses[0].text = ''
    //     prompt.responses[0].value = ''
    //     response = prompt.responses[0]
    //   }
    // }
    const response = await checkinTracker.markCheckinAsComplete({
      sendUpdateApiRequest: true,
      verifyComplete: false,
    })

    if (response && response.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Something went wrong. Please try again later.')
      return
    }

    onSuccess(values)
  }

  function handleSubmit(values) {
    return submit(values)
  }

  if (!checkinTracker) {
    return <Loader overlay={false} />
  }

  const initialValues = {}
  const validationsObject = {}

  checkinTracker.rootNodes.forEach((item) => {
    initialValues[item.code] = item.type === 'multipleSelectMultipleChoice' ? [] : ''
    validations[item.code] = item.required ? validations.REQUIRED : undefined
  })

  return (
    <div className={classNames('CheckinForm', size && `CheckinForm---${size}`, className)}>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationsObject)}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form>
            {checkinTracker.rootNodes.map((item) => {
              const fieldProps = {
                key: item.code,
                label: HtmlParser(Handlebars.compile(item.text)(checkinTracker.metadata)),
                name: item.code,
                required: item.required,
              }

              if (item.type === 'freeTextInput') {
                return (
                  <Field2
                    {...fieldProps}
                    placeholder={item.responses[0]?.placeholder ?? null}
                    type="textarea"
                    onKeyUp={(event) => handleResponse(event.target.value, item.code)}
                  />
                )
              }

              if (item.type === 'singleSelectMultipleChoice') {
                return (
                  <FieldRadioButtons
                    {...fieldProps}
                    options={item.responses}
                    minLabel={item.responses[0].subText}
                    maxLabel={_.last(item.responses).subText}
                    size={size}
                    onChange={(value) => handleResponse(value, item.code)}
                  />
                )
              }

              if (item.type === 'multipleSelectMultipleChoice') {
                return (
                  <FieldCheckboxButtons
                    {...fieldProps}
                    options={item.responses}
                    onChange={(value) => handleResponse(value, item.code)}
                  />
                )
              }

              if (item.type === 'slider') {
                return (
                  <FieldSlider
                    {...fieldProps}
                    isOnboarding={false}
                    options={item.responses}
                    onChange={(value) => handleResponse(value, item.code)}
                  />
                )
              }

              return null
            })}

            {/* <Buttons {...buttonsProps}> */}
            <Button
              {...buttonProps}
              disabled={formikProps.isSubmitting}
              onClick={formikProps.submitForm}>
              {buttonProps.children || 'Submit'}
            </Button>
            {/* </Buttons> */}
          </Form>
        )}
      </Formik>
    </div>
  )
}
